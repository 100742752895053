import { AddPasswordComponent } from "./connexion/add-password/add-password.component";
import { ConnexionComponent } from "./connexion/connexion.component";
import { SigninPoppinComponent } from "./connexion/forms/signin-poppin/signin-poppin.component";
import { AccueilModule } from "./main/accueil/accueil.module";
import { HomeComponent } from "./connexion/home/home.component";
import { ResetPasswordFormComponent } from "./connexion/reset-password-form/reset-password-form.component";
import { SignupComponent } from "./connexion/forms/signup/signup.component";
import { SigninComponent } from "./connexion/forms/signin/signin.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpConfigInterceptor } from "./services/service_interceptor/http-config.interceptor";
import { SharedModule } from "./shared/shared.module";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { NavigationPageComponent } from "./navigation-page/navigation-page.component";
import { ToastrModule, ToastrService } from "ngx-toastr";
import {
  HashLocationStrategy,
  Location,
  LocationStrategy,
} from "@angular/common";
import { OwlModule } from "ngx-owl-carousel";
import { NousRejoindreComponent } from "./nous-rejoindre/nous-rejoindre.component";
import {
  BsDropdownModule,
  ButtonsModule,
  CarouselModule,
  ModalModule,
  TabsModule,
} from "ngx-bootstrap";
import { AddPasswordEtablissementComponent } from "./connexion/add-password-etablissement/add-password-etablissement.component";
import { ResetPasswordEtablissementComponent } from "./connexion/reset-password-etablissement/reset-password-etablissement.component";
import { PremiereCnxComponent } from "./premiere-cnx/premiere-cnx.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NewHomeModule } from "./main/new-home/new-home.module";

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem("token");
    },
    whitelistedDomains: ["example.com"],
    blacklistedRoutes: ["example.com/examplebadroute/"],
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationPageComponent,
    NousRejoindreComponent,
    HomeComponent,
    ConnexionComponent,
    AddPasswordComponent,
    ResetPasswordFormComponent,
    ResetPasswordEtablissementComponent,
    AddPasswordEtablissementComponent,
    PremiereCnxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    OwlModule,
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccueilModule,
    NewHomeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
   // MatomoModule.forRoot()
    
   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ToastrService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: "fr",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
