import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";



@Injectable({
  providedIn: "root",
})
export class MonCompteService {
  url: any = environment.url;
  constructor(public http: HttpClient, private auth: AuthService) {}


  /***************** TOKEN **********************/

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('token'); // Replace with your token retrieval logic
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Vyront ${token}`,
    });
  }
  /*************** MonCompte Management *****************/

  //*** Accueil */
  getAccueil(): Observable<any> {
    return this.http.get(this.url + "MC/client/accueil");
  }
  /**************************/

  //*** Accueil etablissement*/
  getAccueilEtablissement(): Observable<any> {
    return this.http.get(this.url + "MC/etablissement/accueil");
  }
  /**************************/

  //*** Preferences */
  getPreferences(): Observable<any> {
    return this.http.get(this.url + "MC/client/preferences");
  }
  getNewsletters(): Observable<any> {
    return this.http.get(this.url + "MC/client/newsletters");
  }

  AttachPreferences(preferences): Observable<any> {
    let body = preferences;
    return this.http.post(this.url + "MC/client/attach/preferences", body);
  }

  AttachNewsletters(object): Observable<any> {
    let body = object;
    return this.http.post(this.url + "MC/client/attach/newsletters", body);
  }
  /**************************/

  //*** Coordonnees*/
  getCoordonnees(): Observable<any> {
   // return this.http.get(this.url + "MC/client/coordonnees");
   const headers = this.getHeaders();

  return this.http.get(this.url + "MC/client/coordonnees", { headers } );
  }
  getCoordonneesEtablissement(): Observable<any> {
    return this.http.get(this.url + "MC/etablissement/coordonnees");
  }
  updateCoordonnees(Coordonnees, password, confirmePassword): Observable<any> {
    const body = {
      identifiant: Coordonnees.identifiant,
      firstName: Coordonnees.firstName,
      lastName: Coordonnees.lastName,
      dateNaissance: Coordonnees.dateNaissance,
      email: Coordonnees.email,
      telephone: Coordonnees.telephone,
      adresse: Coordonnees.adresse,
      compAdresse: Coordonnees.compAdresse,
      codePostal: Coordonnees.codePostal,
      ville: Coordonnees.ville,
      pays: Coordonnees.pays,
      idCivilite: Coordonnees.idCivilite,
      idFonction: Coordonnees.idFonction,
      password: password,
      confirmePassword: confirmePassword,
    };
    return this.http.put(this.url + "MC/client/update/coordonnees", body);
  }
  /**************************/
  updateCoordonneesEtablissement(Coordonnees, password, confirmePassword): Observable<any> {
    const body = {
      firstName: Coordonnees.firstName,
      lastName: Coordonnees.lastName,
      emailContact: Coordonnees.emailContact,
      telephoneContact: Coordonnees.telephoneContact,
      idFonction: Coordonnees.idFonction,
      password: password,
      confirmePassword: confirmePassword,
      Name: Coordonnees.Name,
      idSecteur: Coordonnees.idSecteur,
      idMetier: Coordonnees.idMetier,
      rcs: Coordonnees.rcs,
      nbreSal: Coordonnees.nbreSal,
      adresse: Coordonnees.adresse,
      codePostal: Coordonnees.codePostal,
      ville: Coordonnees.ville,
      pays: Coordonnees.pays,
      telephone: Coordonnees.telephone,
      idCivilite: Coordonnees.idCivilite,
      hasNewsletter: Coordonnees.hasNewsletter,
    };
    return this.http.put(this.url + "MC/etablissement/update/coordonnees", body);
  }

  updateNewsletter(Coordonnees, etatNew): Observable<any> {
    const body = {
      idEtab: Coordonnees.id,
      etatNew: etatNew
    };
    return this.http.put(this.url + "newsletter", body);
  }
  /******* Notifier fermeture etablissement ********* */
  notifierFermetureEtablissement(idEtab, dateFermeture): Observable<any> {
    const body = {
      idEtablissement: idEtab ,
      dateFermeture: dateFermeture 

    };
    return this.http.put(this.url + "MC/etablissement/notifier/fermeture", body);
  }
  /**************************/
  //*** Abonnement*/
  getAbonnement(): Observable<any> {
    return this.http.get(this.url + "MC/client/abonnement");
  }

  updateAbonnement(Coordonnees): Observable<any> {
    const body = {
      firstName: Coordonnees.firstName,
      lastName: Coordonnees.lastName,
      email: Coordonnees.email,
      idCivilite: Coordonnees.idCivilite,
    };
    return this.http.put(this.url + "MC/client/update/abonnement", body);
  }
  /**************************/

  //*** Commandes*/
  getCommandes(): Observable<any> {
    return this.http.get(this.url + "MC/client/commandes");
  }
  /**************************/

  //*** Reset Password adherent*/
  ResetPassword(email): Observable<any> {
    const body = email;
    return this.http.post(this.url + "MC/client/reset/password", body);
  }

  //*** Reset Password employeur*/
  ResetPasswordEmployeur(email): Observable<any> {
    const body = email;
    return this.http.post(this.url + "MC/etablissement/reset/password", body);
  }
  /**************************/

  //*** Civilites*/
  getCivilites(): Observable<any> {
    return this.http.get(this.url + "MC/civilites");
  }
  /**************************/

  // Activites
  getActivites(): Observable<any> {
    return this.http.get(this.url + "segments");
  }

  // Metiers
  getMetiers() {
    return this.http.get(this.url + "metiers");
  }

  // segments
  getMetiersSecteur(secteurId) {
    return this.http.get(this.url + "metiers/" + secteurId);
  }

  // fonctions
  getFonctions() {
    return this.http.get(this.url + "fonctions");
  }
  /***
   * codePromo Renouvelement
   * */
  codePromoRenouvelement(codePromo,typeCarte,mode): Observable<any> {
   // const body = codePromo;
   
    const body = {
      codePromo: codePromo,
      typeCarte: typeCarte,
      mode: mode
    };

    return this.http.post(this.url + "MC/renouvelement/check/codePromo", body);
  }

    /***
   * codePromo Renouvelement
   * */
    codePromoFraisInscrit(codePromo,idFrais): Observable<any> {
      // const body = codePromo;
      
       const body = {
         codePromo: codePromo,
         fraisInscrit: idFrais
       };
   
       return this.http.post(this.url + "MC/check/frais/inscription", body);
     }
  /**************************/

  //*** codePromo Inscription */
  codePromoInscription(codePromo): Observable<any> {
  const body = codePromo;
   
    return this.http.post(this.url + "MC/inscription/check/codePromo", body);
  }
  /**************************/

  //**** Inscription */
  Inscription(login): Observable<any> {
    const body = login;

    return this.http.post(this.url + "MC/etablissement/inscrire", body);
  }

  InscriptionAdherent(login): Observable<any> {
    const body = login;
    return this.http.post(this.url + "MC/client/inscrire", body);
  }

  addClientPremiereCnx(login): Observable<any> {
    const body = login;
    console.log(body, 'body')
    return this.http.post(this.url + "add/clientPreCnx", body);
  }

  updateClientPremiereCnx(idClient, login): Observable<any> {
    const body = login;
    return this.http.post(this.url + 'update/clientFOpremCnx/' + idClient, body)
  }
  /**************************/
  
    //**** FraisInscrit */
    FraisInscrit(numbreCarte): Observable<any> {
      const body = {
        nbreCartes: numbreCarte
      }
      return this.http.post(this.url + "MC/numberFraisDoss", body);
    }
    /**************************/
  
  //**** Product Price */
  ProductPrice(): Observable<any> {
    return this.http.get(this.url + "MC/prixProduit");
  }
  /**************************/

  //**** paiement banque */
  
  paiementBanque(banque): Observable<any> {
    let body = banque
    return this.http.post(this.url + 'paiementBanque', body)
  }
  updateNewsletterClient(Coordonnees, etatNew): Observable<any> {
    const body = {
      idClient: Coordonnees.idClient,
      etatNew: etatNew
    };
    return this.http.put(this.url + "client/newsletter", body);
  }

  createCarteAdherent(idCarte): Observable<any>{
    const body = {
      idCarte: idCarte,
    };
    return this.http.post(this.url + "createCarte",body);
  }

  changeEtabEspaceAdh(coordonnees, dataEtab): Observable<any> {
    const body = {
      numeroAdherent: coordonnees.identifiant,
      nom: coordonnees.firstName,
      prenom: coordonnees.lastName,
      telephone: coordonnees.telephone,
      mail: coordonnees.email,
      nomEtab: dataEtab.nomEtab,
      villeEtab: dataEtab.ville,
      codePostalEtab: dataEtab.codePostal,
      paysEtab: dataEtab.pays,
      numRcs: dataEtab.numRcs,
      description: dataEtab.description
    };
    return this.http.post(this.url + "MC/client/modifier/etablissement", body);
  }
  
  envoyerDemande(coordonnees, validerDmd): Observable<any> {
    const body = {
      numeroAdherent: coordonnees.identifiant,
      nom: coordonnees.firstName,
      prenom: coordonnees.lastName,
      mode:validerDmd.mode,
      description: validerDmd.description,
      typeCarte:validerDmd.typeCarte,
    };
    
    return this.http.post(this.url + "MC/client/sendsMails", body);
  }
  envoyerDemandeAdh(coordonnees, validerDmd ): Observable<any> {
      const body = {
      numeroAdherent: coordonnees.identifiant,
      nom: coordonnees.firstName,
      prenom: coordonnees.lastName,
      mode:validerDmd.mode,
      typeCarte:validerDmd.typeCarte,
      description: validerDmd.description
    };
    return this.http.post(this.url + "MC/client/sendsMails", body);
  }
  envoyerDemandeEtab(coordonnees, etabInfo): Observable<any> {
    const body = {
      numeroAdherent: coordonnees.identifiant,
      nom: coordonnees.firstName,
      prenom: coordonnees.lastName,
      nomEtab:etabInfo.nomEtab,
      paysEtab: etabInfo.pays,
      numRcs: etabInfo.numRcs,
      description: etabInfo.description,
      villeEtab: etabInfo.ville,
      codePostalEtab: etabInfo.codePostal,
    };
    return this.http.post(this.url + "MC/client/modifier/etablissement", body);
  }
  detailAdherent(idAdherent): Observable<any> {
    return this.http.get(
      this.url + "client/" + idAdherent
    );
  }
  // recevoir carte physique
  checkboxCarte(clientId, checked) {
    const body = {
      carte: checked
    }
    return this.http.post(this.url + "recevoirCarte/" + clientId, body);
  }

  sendsMailsHome(demandeInfo) {
    const body = {
      nom: demandeInfo.nom,
      prenom: demandeInfo.prenom,
      tel: demandeInfo.tel,
      email: demandeInfo.email,
     
    };
    return this.http.post(this.url + "sendsMailsFO", body);
  }


  clientCryptePartenaire(idClient,codeCe){
    return this.http.get(this.url + "clients/crypte/"+ idClient +"/"+codeCe);
  }


  addDossierFO(dossier): Observable<any> {
    let body = dossier
    return this.http.post(this.url + 'MC/add/dossier', body)
  }
  addReglementFO(reglement): Observable<any> {
    let body = reglement
    return this.http.post(this.url + 'MC/add/reglement', body)
  }

  listCartes(data): Observable<any> {
    let body = data;
    return this.http.post(this.url + "MC/filtre/cartes", body);
  }

  listCommandes(idEtab): Observable<any> {
    return this.http.get(this.url + "MC/EtablissementDossiers/" +idEtab);
  }

  envoyerCodeUniq(carte, data): Observable<any> {
    return this.http.post(this.url + "MC/client/sendsCodeUnique", data);
  }

  envoyerDemandeCartes(data): Observable<any> {
    return this.http.post(this.url + "MC/client/sendsMailsCartes", data);
  }
}


