import { MonCompteService } from './../../services/mon-compte.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { Router } from "@angular/router";
import { ChartComponent } from "@app/main/chart/chart.component";
import { TabsChartComponent } from "@app/main/chart/tabs-chart/tabs-chart.component";
import { OffresService } from "@app/services/offres.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from 'environments/environment';

@Component({
  selector: "mytribu-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  url: any = environment.urlImage;
  constructor(
    public auth: AuthService,
    public router: Router,
    public home: OffresService,
    public MonCompte: MonCompteService,
  ) {}
  accueill: any = [];
  lienCouverture: any ='';
  srcCouverture: any ='';
  lienCouvertureEtab: any ='';
  srcCouvertureEtab: any ='';
  client: any = {};
  offres: any;
  TypesOffres: any;
  id: any;
  href: string = "";
  scrWidth: any;
  idSect: any;
  ngOnInit() {
    this.scrWidth = window.innerWidth;
    console.log(localStorage);
    this.idSect=localStorage.getItem("idSecteur");      
    console.log('idSecteur',this.idSect)
    console.log('scrWidth',this.scrWidth)
    if (this.scrWidth < 767) {
        document.getElementById("webMenu").style.display= "none";
        document.getElementById("mobileMenu").style.display= "block";
      }else{
        document.getElementById("mobileMenu").style.display= "none";
        document.getElementById("webMenu").style.display= "block";
      }
    

    this.client = this.auth.getClient();
   
    this.href = this.router.url;
    let result = this.href.includes("/espaceEtablissement");
    if(result === false){   //espace client
      this.Accueil();
    } else {
      this.AccueilEtablissement();
    }
    this.getTypeOffres();
    //this.accueill = data.EspaceClient;
  }

  deconnexion() {
    console.log(localStorage, 'local')
    const clientNonInscri = localStorage.getItem("clientNonInscri");
    if (!clientNonInscri) {
      localStorage.clear();
    }
    this.router.navigate(["connexion"]);
  }

  Accueil() {
    this.MonCompte.getAccueil()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.accueill = res;
        this.idSect=res.clientInfos.idSecteur
        console.log('accueil',this.accueill)
        localStorage.setItem('cartePhysique' , res.estCarte);
        localStorage.setItem('couvertureAccueil' , res.couvertureAccueil)
        localStorage.setItem('lienAccueil' , res.couvertureAccueilLien)
        localStorage.setItem('typeCarte' , res.clientInfos.infoCarte)
        localStorage.setItem('idSecteur' , res.clientInfos.idSecteur)
        if (res.couvertureEspaceClientLien !== undefined){
          this.lienCouverture = res.couvertureEspaceClientLien;
          this.srcCouverture = this.url + res.couvertureEspaceClient;
          localStorage.setItem('lienCouvertureClient' , this.lienCouverture)
          localStorage.setItem('srcCouvertureClient' , this.srcCouverture)
        } else {
          localStorage.setItem('lienCouvertureClient' , "")
          localStorage.setItem('srcCouvertureClient' , this.url + "assets/img/bg_compte.png")
        }
      })
  }
  AccueilEtablissement() {
    this.MonCompte.getAccueilEtablissement()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.accueill = res;
        localStorage.setItem('lienAccueil' , res.couvertureAccueilLien)
        localStorage.setItem('couvertureAccueil' , res.couvertureAccueil)
        if (res.couvertureEspaceClientLien !== undefined || res.couvertureEspaceClientLien !== ''){
          this.lienCouverture = res.couvertureEspaceClientLien;
          this.srcCouverture = this.url + res.couvertureEspaceClient;

          localStorage.setItem('lienCouvertureEtab' , this.lienCouverture)
          localStorage.setItem('srcCouvertureEtab' , this.srcCouverture)
        } else {
          localStorage.setItem('lienCouvertureEtab' , "")
          localStorage.setItem('srcCouvertureEtab' , this.url + "assets/img/bg_compte.png")
        }
      });

      console.log('localStorage MH ***', localStorage )   ;

  }
  GoTo() {
    this.router.navigate(["espaceClient"]);
  }

  GoHome() {
    this.href = this.router.url;
    let result = this.href.includes("/espaceEtablissement");
    if(result === false){
      this.router.navigate(["home"]);
    }  }

  getTypeOffres() {

    console.log(this.idSect,"this.idSect")
    this.home
      .getOffresBySect(this.idSect)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
   
        this.TypesOffres = res;
        if (!this.id && res[0]) {
          this.id = res[0].id;
        }
        for (let index = 0; index < this.TypesOffres.length; index++) {
          const el = this.TypesOffres[index];
        }
      });
  }
}
