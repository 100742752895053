import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { MonCompteService } from "@app/services/mon-compte.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "mytribu-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() connect = new EventEmitter();
  showPass = false;
  secteur: string = "salarie";
  noWrap = true;
  modalRefMonCompte?: BsModalRef | null;
  modalRefPassEt?: BsModalRef;
  modalRefPassAd?: BsModalRef;
  modalRef?: BsModalRef;
  errormessageAdherent: any = "";
  errormessagePremierCnx: any = "";

  clientIdentifiant: any;
  // client: any = {
  //   login: "",
  //   password: "",
  // };
  employeur: any = {
    login: "",
    password: "",
  };
  salarie: any = {
    login: "",
    password: "",
  };
  premierCnx: any = {
    email: "",
    codeUsageUq: ""
  }
  requiredCnx = false;
  resetPass = {
    email: "",
  };
  resetPassEm = {
    email: "",
  };
  sendMail = false;
  click = false;
  errormessage: any = "";
  errorPass: any = "";
  errorPassEm: any = "";
  successPass: any = "";
  successPassEm: any = "";
  showPassword: any = false;
  closeModal: any;
  dataPremiere: any = [];
  constructor(
    public auth: AuthService,
    public router: Router,
    private modalService: BsModalService,
    private cookies: CookieService,
    public monCompte: MonCompteService
  ) {}

  item: any = 1;
  openMenu = true;
  pages: any;
  name: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  ngOnInit() {
   
    this.getDynamicPage();
  }
  goto(id) {
    this.connect.emit({ id: this.item });
    this.router.navigate(["connexion"]);
  }
  navigate(url) {
    localStorage.setItem("pages", JSON.stringify(url));
    this.router.navigate(["/home/" + url]);
  }

  nivigate(url) {
    console.log("/home/" + url);
    localStorage.setItem("pages", JSON.stringify(url));
    this.router.navigate(["/home/" + url]);
  }

  navigateGeneriquePage(item) {
    console.log(item);
    localStorage.setItem("pages", JSON.stringify(item));
    this.router.navigate(["/home/" + item.segment]);
  }
  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.pages = res;
      });
  }
  LoginAdherent(myform) {
    this.requiredCnx = true;
    localStorage.clear();
    if (myform.valid == true) {
      this.errormessageAdherent = "";
      if (this.salarie.login != "" && this.salarie.password != "") {
        this.auth
          .LoginClient(this.salarie)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (res) => {
              console.log('auth res',res)
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessageAdherent = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              localStorage.setItem("idEtabl", res.user.idEtabl);
              localStorage.setItem("idSecteur", res.user.idSecteur);
              localStorage.setItem("nameSecteur", res.user.nameSecteur);
              localStorage.setItem("infoCarte", res.user.infoCarte);
              //localStorage.setItem("password", this.salarie.password);
              if (localStorage.getItem("clientNonInscri")) {
                localStorage.removeItem("clientNonInscri");
              }

      

              this.router.navigate(["home"], {
                queryParams: { modalConnexion: "true" },
              });
              this.modalRefMonCompte.hide();
            },
            (MsgError) => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessageAdherent =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessageAdherent =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              }
              this.router.navigate(["connexion"]);
            }
          );
      } else {
        this.errormessageAdherent =
          this.salarie.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }

  /*** Login employeur */
  LoginClient(myform) {
    // localStorage.clear()
    if (myform.valid == true) {
      this.errormessage = "";
      if (this.employeur.login != "" && this.employeur.password != "") {
        this.auth
          .LoginEmployeur(this.employeur)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (res) => {
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessage = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              localStorage.setItem("idEtabl", res.user.idEtabl);
              localStorage.setItem("idSecteur", res.user.idSecteur);
              localStorage.setItem("nameSecteur", res.user.nameSecteur);
              localStorage.setItem("infoCarte", res.user.infoCarte);
              // localStorage.setItem("password", this.employeur.password);
              if (localStorage.getItem("clientNonInscri")) {
                localStorage.removeItem("clientNonInscri");
              }
       
              this.router.navigate(["espaceEtablissement"], {
                queryParams: { modalConnexion: "true" },
              });
              this.modalRefMonCompte.hide();
            },
            (MsgError) => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessage =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessage =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              }
              else if (
                MsgError.err.error &&
                MsgError.err.error.message == "Compte désactivé."
              ) {
                this.errormessage =
                  "Votre compte n’est plus actif, veuillez contacter notre service client pour une demande de réactivation .";
              }
              this.router.navigate(["connexion"]);
            }
          );
      } else {
        this.errormessage =
          this.employeur.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRefMonCompte = this.modalService.show(
      template,
      Object.assign({}, { class: "container gray modal-xl " })
    );
  }

  openModalAdherent(templateAdherent: TemplateRef<any>) {
    this.modalRefMonCompte = this.modalService.show(templateAdherent);
  }

  openModalEmployeur(templateEmployeur: TemplateRef<any>) {
    this.modalRefMonCompte = this.modalService.show(templateEmployeur);
  }

  /*** Reset Password employeur*/
  ResetpasswordEmployeur() {
    this.click = true;
    if (this.resetPassEm.email !== "") {
      this.monCompte
        .ResetPasswordEmployeur(this.resetPassEm)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPassEm = "";
            this.successPassEm =
              "Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l'avez pas reçu.";
            this.resetPassEm.email = "";
            let self = this;
            setTimeout(() => {
              self.successPassEm = "";
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message == "Email incorrecte.") ||
              resError.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorPassEm =
                "Désolé, cette adresse e-mail n'est associée à aucun compte Etablissement.";
            }
            else if (resError.err.error &&
              resError.err.error.message == "Compte inactif.") 
           {
            this.errorPassEm =
              "Votre compte n’est plus actif, veuillez contacter notre service client pour une demande de réactivation .";
          }
          }
        );
    } else {
      this.errorPassEm =
        this.resetPassEm.email == "" ? "Ce Champ est obligatoire" : "";
    }
  }

  /*** Reset Password adherent*/
  ResetpasswordSalarie() {
    this.click = true;
    if (this.resetPass.email !== "") {
      this.monCompte
        .ResetPassword(this.resetPass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPass = "";
            this.successPass =
              "Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l'avez pas reçu.";
            this.resetPass.email = "";
            let self = this;
            setTimeout(() => {
              self.successPass = "";
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message == "Email incorrecte.") ||
              resError.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorPass =
                "Désolé, cette adresse e-mail n'est associée à aucun compte Adhérent.";
            }
          }
        );
    } else {
      this.errorPass =
        this.resetPass.email == "" ? "Ce Champ est obligatoire" : "";
    }
  }

  onInscription() {
    this.router.navigate(["/inscription"]);
    this.closeModalMoncompte();
  }

  fermeEtablissement() {
    this.modalRefPassEt.hide();
    this.resetPassEm.email = "";
  }
  fermeAdherent() {
    this.modalRefPassAd.hide();
    this.resetPass.email = "";
  }
  fermerCompte() {
    this.modalRefMonCompte?.hide();
  }

  /**
   * open & close modal MonCompte
   */
  openModalMonCompte(template: TemplateRef<any>) {
    localStorage.clear();
    this.router.navigate([""]);
    this.modalRefMonCompte = this.modalService.show(
      template,
      Object.assign({ id: 1 }, { class: "container gray modal-xl " })
    );
  }
  closeModalMoncompte() {
    if (!this.modalRefMonCompte) {
      return;
    }
    this.modalRefMonCompte.hide();
    this.modalRefMonCompte = null;
  }
  /**
   * open & close modal Pass Adherent
   */
  openModalPassAd(templateAdherent: TemplateRef<any>) {
    this.modalRefPassAd = this.modalService.show(
      templateAdherent,
      Object.assign({ id: 2 })
    );
    this.resetPass.email = "";
  }
  closeModalPassAd(modalId?: number) {
    this.modalService.hide(modalId);
    this.modalRefPassAd = null;
  }
  /**
   * open & close modal Pass Employeur
   */
  openModalPassEmp(templateEmployeur: TemplateRef<any>) {
    this.modalRefPassEt = this.modalService.show(
      templateEmployeur,
      Object.assign({ id: 3 })
    );
  }
  closeModalPassEmp(modalId?: number) {
    this.modalService.hide(modalId);
    this.modalRefPassEt = null;
  }
  showPwd(){
    this.showPass = false;
  }

  loginPremiereCnx(){
    console.log('+++',this.premierCnx)
    this.requiredCnx = false;
    this.errormessagePremierCnx = "";
    if (this.premierCnx.codeUsageUq != "" && this.premierCnx.email != "") {
      this.auth
        .LoginPremiereCnx(this.premierCnx)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.auth.setAuth(res.token);
            console.log(res,'res')
            this.auth.setClient(res.carte);
            localStorage.setItem("numeroCarte", res.carte.numeroCarte);
            localStorage.setItem("idCarte", res.carte.idCarte);
            localStorage.setItem("dateExpiration", res.carte.dateExpiration);
            localStorage.setItem("idEtab", res.carte.idEtab);
            localStorage.setItem("nameEtab", res.carte.nameEtab);
            localStorage.setItem("idSecteur", res.carte.idSecteur);
            localStorage.setItem("nameSecteur", res.carte.nameSecteur);
            localStorage.setItem("nameMetier", res.carte.nameMetier);
            localStorage.setItem("idMetier", res.carte.idMetier);
            localStorage.setItem("hasCarte", res.carte.hasCarte);
            localStorage.setItem("codeUsageUq", this.premierCnx.codeUsageUq);
            localStorage.setItem("idAdherent", res.carte.idAdherent);
            localStorage.setItem("email", this.premierCnx.email);
            localStorage.setItem("civilite", res.carte.idCiv);
            // this.modalRef.hide();
            // this.router.navigate(["connexion"]);
            this.router.navigate(["premiereConnexion"]);
            // if(res.carte.hasCarte === 0){
            // } else {  
            //   this.router.navigate(["home"]);
            // }
            this.dataPremiere = res.carte.info;
            this.modalRefMonCompte.hide();
          },
          (MsgError) => {
            if (
              MsgError.err.error &&
              MsgError.err.error.message == "Code usage unique est introuvable"
            ) {
              this.errormessagePremierCnx =
                "Le code à usage unique saisi est incorrect.";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Carte est expiré."
            ) {
              this.errormessagePremierCnx =
                "La carte est expirée.";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Email incorrecte"
            ) {
              this.errormessagePremierCnx =
                "L\'e-mail saisi est incorrect";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "email existe"
            ) {
              this.errormessagePremierCnx =
                "L\'e-mail saisi existe déjà";
            }else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Etablissement désactivé"
            ) {
              this.errormessagePremierCnx =
                "Etablissement est désactivé";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Code usage unique est utilisé"
            ){
              this.errormessagePremierCnx =
              "Ce code a déjà été utilisé, veuillez renseigner un autre code à usage unique.";
            }
            this.router.navigate(["connexion"]);
          }
        );
    } else {
      this.errormessagePremierCnx = 
        this.premierCnx.codeUsageUq == ""
          ? "Code à usage unique est obligatoire"
          : "Email obligatoire";
    }
 
 }
 togglePassword() {
  this.showPass = !this.showPass;
  const passwordInput = document.querySelector('input[name="password"]') as HTMLInputElement;
  if (passwordInput) {
    passwordInput.focus();
  }
}

}
