import { Component, OnInit, ViewChild, HostListener,ElementRef, Renderer2} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RouterServiceService } from '@app/services/router-service.service';
import { AuthService } from '@app/services/auth.service';
import { Subject } from 'rxjs';
import { TabsetComponent, etLocale } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'mytribu-navigation-page',
  templateUrl: './navigation-page.component.html',
  styleUrls: ['./navigation-page.component.scss']
})
export class NavigationPageComponent implements OnInit {
  @ViewChild('tabset', {static: true}) tabset: TabsetComponent;
   pageContent: any ;
  segmentPath = '';
  private _unsubscribeAll: Subject<any> = new Subject()
  page: any;
  url: any = '';
  urlImg: any = '';
  mySubscription: any;
  head: any = false;
  pages: any;
  itemsPerSlide = 4;
  scrWidth:any;
  urlImage: any = environment.urlImage;
  showIndicator = false;
  singleSlideOffset = true;
  slider_avantages = [
    {
      title: "Parc de loisirs",
      image: "assets/v3/images/avantages/parc.png",
      id_block: "parc",
    },
    {
      title: "Prêt-à-porter",
      image: "assets/v3/images/avantages/pretaporter.png",
      id_block: "pretaporter",
    },
    {
      title: "Sports & Loisirs",
      image: "assets/v3/images/avantages/sports.png",
      id_block: "sports",
    },
    {
      title: "Transports",
      image: "assets/v3/images/avantages/transport.png",
      id_block: "transport",
    },
    {
      title: "Bien-être",
      image: "assets/v3/images/avantages/bienetre.png",
      id_block: "bienetre",
    },
    {
      title: "Cinéma",
      image: "assets/v3/images/avantages/cinema.png",
      id_block: "cinema",
    },
    {
      title: "Concerts & Spectacles",
      image: "assets/v3/images/avantages/concerts.png",
      id_block: "concerts",
    },
    {
      title: "Vie quotidienne",
      image: "assets/v3/images/avantages/viequotidienne.png",
      id_block: "viequotidienne",
    },
    {
      title: "Destination vacances",
      image: "assets/v3/images/avantages/vacances.png",
      id_block: "vacances",
    },
    {
      title: "Location",
      image: "assets/v3/images/avantages/location.png",
      id_block: "location",
    },
  ];
  @ViewChild('ski') blocPartenaires: ElementRef;

  constructor(private renderer:Renderer2, public auth: AuthService, public activeRoute: ActivatedRoute ,private meta:Meta,private titleService:Title,
    private route: Router, private routerService: RouterServiceService) {
      this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.route.navigated = false;
      }
    }); 
    this.url = route.url; 
    console.log("1111")
    console.log(this.url)
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        console.log(this.scrWidth, 'xidth', event)
        if (this.scrWidth < 767){
          this.itemsPerSlide = 1;
          //console.log(this.itemsPerSlide, '++++')
        }else {
          this.itemsPerSlide = 4;
        }
        return this.itemsPerSlide;
        //console.log(this.itemsPerSlide, 'event')
  }
  ngOnInit() {
    //this.pageContent= null;
    if (window.screen.width < 767){
      this.itemsPerSlide = 1;
    }
    console.log("222222")

  // this.getScreenSize();
    this.getStaticPage();
    this.getDynamicPage();
    this.auth.getToken().subscribe(res => {
      if (res && Object.keys(res).length > 0) {
      this.head =
          localStorage.getItem('token') && localStorage.getItem('token') !== ''

      }
    })

  }
  getStaticPage() {
    let path =  this.url;
    //this.pageContent= null;
    let last = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.segmentPath = JSON.parse(localStorage.getItem('pages'))
    console.log("last",last)
    console.log("33333")
    this.auth
      .StaticPage(last)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        console.log("resres",res)
        this.pageContent = res;

        if(res.infoSeo != undefined ){
          if (res.infoSeo.contenuSeo != undefined)
            this.meta.addTag({ name: 'description',  content: res.infoSeo.contenuSeo });
          if (res.infoSeo.metaSeo != undefined)
            this.meta.addTag({ name: 'keywords',  content: res.infoSeo.metaSeo });
          if (res.infoSeo.titreSeo != undefined){
            this.titleService.setTitle(res.infoSeo.titreSeo);
          }else{
            this.titleService.setTitle('MyTribu');
          }
        }else{
          this.meta.removeTag('name="description"');
          this.meta.removeTag('name="keywords"');
          this.titleService.setTitle('MyTribu');
        }
        this.urlImg = this.urlImage + res.image;
        console.log("4444")

      })
      console.log(this.pageContent)
      console.log(this.urlImg)

  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.renderer.listen('window','load',()=>{
      console.log("55555")
     
      // Divisez l'URL en utilisant le symbole '#'
      let parts = this.url.split('#');
      // La partie après '#' est stockée dans le deuxième élément du tableau
      let chaineApresHash = parts[1];
      console.log(chaineApresHash); // Cela vous donnera la chaîne après '#'
      this.blocPartenaires.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      console.log( this.blocPartenaires.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' }));
  
  

    });
        
   
  }
  selectTab(tabId?) {
    const id = tabId.id ? tabId.id : 1;
    this.tabset.tabs[id].active = true;

  }
  navigateGeneriquePage(item,id_block){

    console.log("navigateGeneriquePage")
    console.log(item)
    console.log(id_block)
    localStorage.setItem('pages', JSON.stringify(item));
    this.route.navigate(['/home/' + item.segment]);
    
  }
  getDynamicPage() {
    this.auth
    .StaticPageDynamic()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
      this.pages = res;
    })
}

}
